import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const login = async (formData) => {
  const response = await axios.post('/api/auth/login', formData);
  localStorage.setItem('auth_token', response.data.token);
  return response;
};

export const adminLogin = async (userId) => {
  const headers = getHeaders();
  const response = await axios.post('/api/auth/admin/login', { userId }, { headers });
  localStorage.setItem('auth_token', response.data.token);
  return response;
};

export const signUp = async (data) => {
  const response = await axios.post('/api/auth/signup', data);
  localStorage.setItem('auth_token', response.data.token);
  return response;
};

export const getUser = async () => {
  const headers = getHeaders();
  const res = await axios.get('/api/user', { headers });
  return res.data;
};

export const logout = async () => {
  localStorage.removeItem('auth_token');
  await axios.post('/api/logout');
};

export const sendEmailForResetPassword = async (data) => {
  const res = await axios.post('api/auth/submit-reset-pass-email', data);
  return res.data;
};

export const resetPassword = async (data) => {
  const res = await axios.post('api/auth/reset-password', data);
  return res.data;
};
