import React, { useContext, useEffect, useState } from 'react';
import { getUser } from '../api/auth';

const CurrentUserContext = React.createContext();
export const useCurrentUser = () => useContext(CurrentUserContext);

export const CurrentUserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getUser()
      .then((res) => {
        setUser(res);
        setLoading(false);
      })
      .catch((e) => {
        setUser(null);
        setLoading(false);
        localStorage.removeItem('auth_token');
      });
  }, []);

  return (
    <CurrentUserContext.Provider value={{
      user,
      setUser,
    }}
    >
      {!loading && children}
    </CurrentUserContext.Provider>
  );
};
