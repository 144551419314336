import axios from 'axios';
import { getHeaders } from '../utils/utils';

const cancelTokenSource = {};

export const getStats = async (id, filters, type, limit = 100) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/${id}/${type}/${limit}?${filters}`, { headers });
  return res.data;
};

export const getOfferUrls = async (id) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/offer-urls/${id}/`, { headers });
  return res.data;
};

export const getGAStats = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/stats/ga/`, { headers, params });
  return res.data;
};
