import { createTheme, colors } from '@material-ui/core';

const theme = createTheme({
  typography: {
    fontFamily: [
      '"Montserrat"',
      'sans-serif',
    ].join(','),
  },
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
    },
    primary: {
      contrastText: '#ffffff',
      main: '#5664d2',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        color: '#fff',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#5664d2',
        '&.Mui-focused': {
          color: '#f7a128',
        },
        '&.Mui-disabled': {
          color: '#ffffffa3',
        },
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: '#fff',
          color: '#000',
          borderRadius: 10,
          padding: 10,
        },
      },
      iconOutlined: {
        color: '#000 !important',
      },
      root: {
        '&$outlined': {
          backgroundColor: '#fff',
          color: '#000',
          borderRadius: 10,
          padding: 10,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          // borderColor: 'none',
          borderRadius: 10,
        },
        '&:hover $notchedOutline': {
          borderColor: 'none',
        },
        '&$focused $notchedOutline': {
          borderColor: 'none',
        },
      },
    },
    MuiButton: {
      label: {
        fontWeight: 'bold',
      },
    },
  },
});

export default theme;
