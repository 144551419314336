import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import { logout } from '../../api/auth';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import logo from '../../assets/images/mowpod-boost-logo.png';
import { Drawer, Hidden, IconButton, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import iHeartLogo from '../../assets/images/logo-iheartmedia.png';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: '#2a2a2a',
  },
  nested: {
    paddingLeft: 20,
    paddingRight: 60,
    color: '#fff',
  },
}));

export default function Header({ hideMenu }) {
  const classes = useStyles();
  const history = useHistory();
  const { user, setUser } = useCurrentUser();
  const [openMenu, setOpenMenu] = useState(false);
  const handleLogout = async () => {
    await logout();
    setUser(null);
    history.push('/login');
  }

  return (
    <header>
      <div className={cx({ 'login-header': hideMenu }, 'header', "container")}>
        <img className="heder--logo" src={logo} alt="mowpod boost logotype" />
        <img className="heder--logo heder--megaphoneLogo" src={iHeartLogo} alt="mowpod boost logotype" />
        {!hideMenu && (
          <>
            <Hidden smUp>
              <IconButton className="header__logout" color="primary" aria-label="upload picture" onClick={() => setOpenMenu(true)}>
                <FontAwesomeIcon icon={faBars} style={{ color: "#ff812e" }} />
              </IconButton>
              <Drawer classes={{ paper: classes.root }} anchor="right" open={openMenu} onClose={() => setOpenMenu(false)}>
                <List>
                  {(!!user) ?
                    (
                      <>
                        {!user.admin ? (
                          <>
                            <ListItem button className={classes.nested}>
                              <ListItemText><a onClick={() => history.push('/')} >Campaigns</a></ListItemText>
                            </ListItem>
                          </>
                        ) : (
                          <>
                            <ListItem button className={classes.nested}>
                              <ListItemText><a onClick={() => history.push('/admin')} >Users</a></ListItemText>
                            </ListItem>
                          </>
                        )}
                        <ListItem button className={classes.nested}>
                          <ListItemText><a onClick={handleLogout} >Logout</a></ListItemText>
                        </ListItem>
                      </>
                    ) : (
                      <ListItem button className={classes.nested}>
                        <ListItemText><a onClick={() => history.push('/login')} >Login</a></ListItemText>
                      </ListItem>
                    )}
                </List>
              </Drawer>
            </Hidden>
            <Hidden xsDown>
              {(!!user) ? (
                <div style={{ display: 'flex' }}>
                  {!user.admin ? (
                    <div className="header__logout"><a onClick={() => history.push('/')} >Campaigns</a></div>
                  ) : (
                    <>
                      <div className="header__logout"><a onClick={() => history.push('/admin')} >Users</a></div>
                    </>
                  )}
                  <div className="header__logout"><a onClick={handleLogout} >Logout</a></div>
                </div>
              ) : (
                <div style={{ display: 'flex' }}>
                  <div className="header__logout"><a onClick={() => history.push('/login')} >Login</a></div>
                </div>
              )}
            </Hidden>
          </>
        )}
      </div>
    </header>
  )
}