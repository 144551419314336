import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';

const options = {
  responsive: true,
  aspectRatio: 2,
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        usePointStyle: true,
        color: '#fff',
      },
    },
  },
};

export default function TopDevices({ data = [], loading }) {
  const initialState = {
    labels: [],
    datasets: [{
      label: '',
      backgroundColor: [],
      data: [],
      hoverOffset: 4,
    }],
  };
  const [chartData, setChartData] = useState({ ...initialState });
  useEffect(() => {
    if (data.length) {
      const currentData = data.reduce((accum, curr) => {
        accum.labels.push(curr.Stat.device_brand);
        accum.datasets[0].data.push(+curr.Stat.conversions || +curr.Stat.unique_clicks);
        const colorDec = (Math.random() * 16777215);
        const colorNotBlack = colorDec > 3092271 ? colorDec : colorDec + 3092271 ;
        const colorHex = `#${Math.floor(colorNotBlack).toString(16)}`;
        accum.datasets[0].backgroundColor.push(colorHex);
        return accum;
      }, initialState);
      setChartData(currentData);
    }
  }, [data]);

  const render = () => data.length
    ? <Doughnut data={chartData} options={options} />
    : (<Box component="span" p={10}>
      <Typography variant='h6'>No Data</Typography>
    </Box>);
  ;

  return !loading ? (render()) : (<CircularProgress />);
}
