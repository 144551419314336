import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const getCampaignGroups = async (params) => {
  const headers = getHeaders();
  const res = await axios.get('/api/stack-adapt/campaign-groups', { headers, params });
  return res.data;
};

export const getStackStats = async (params) => {
  const headers = getHeaders();
  const res = await axios.get('/api/stats/stack-adapt', { headers, params });
  return res.data;
};

export const getStackGeos = async (params) => {
  const headers = getHeaders();
  const res = await axios.get('/api/stats/stack-adapt/geos', { headers, params });
  return res.data;
};

export const getStackDevices = async (params) => {
  const headers = getHeaders();
  const res = await axios.get('/api/stats/stack-adapt/devices', { headers, params });
  return res.data;
};


