import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';

export default function TopInterests({ data = [], loading }) {
  const renderTopInterests = () => {
    if (!data.length) {
      return (
        <Box component="span" p={10}>
          <Typography variant="h6">No Data</Typography>
        </Box>
      );
    }
    let top;

    let groups = data.reduce((acc, obj) => {
      let key = obj.dimensionValues[0].value;
      if (!acc[key]) {
        acc[key] = { name: key, num: 0 };
      }
      acc[key].num += +obj.metricValues[0].value;
      return acc;
    }, {});

    return Object.values(groups)
      .slice(0, 10)
      .map((item, i) => {
        const stat = +item.num;
        if (i === 0) {
          top = stat;
        }
        const value = Math.round((stat / top) * 100);
        return (
          <Grid
            key={i}
            style={{ paddingBottom: 15 }}
            container
            direction="row"
            item
            md={12}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid
              style={{ paddingRight: 15, overflowWrap: 'anywhere' }}
              container
              direction="row"
              item
              md={4}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Typography style={{ fontSize: 16, fontWeight: 'bold' }}>{item.name}</Typography>
            </Grid>
            <Grid
              container
              direction="row"
              item
              md={5}
              justifyContent="flex-start"
              alignItems="center"
            >
              <div
                style={{
                  width: `${value}%`,
                  height: 10,
                  backgroundColor: '#f9a119',
                  borderRadius: 7,
                  opacity: value / 100,
                }}
              />
            </Grid>
            <Grid
              container
              direction="row"
              item
              md={3}
              justifyContent="flex-end"
              alignItems="center"
            >
              <Typography style={{ fontSize: 14, fontWeight: 'bold' }}>{stat}</Typography>
            </Grid>
          </Grid>
        );
      });
  };
  return loading ? (
    <Grid
      style={{ paddingBottom: 15 }}
      container
      direction="row"
      item
      md={12}
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Grid>
  ) : (
    renderTopInterests()
  );
}
