import { Box, CircularProgress, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';

const options = {
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
      position: 'right',
    },
  },
};

export default function TopAgeAndGender({ data = [], loading, unknown = false, barPercentage }) {
  const initialState = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: [],
        barPercentage,
      },
    ],
  };
  const [chartData, setChartData] = useState({ ...initialState });

  useEffect(() => {
    if (data.length) {
      const currentData = data.reduce((accum, curr) => {
        if (curr.dimensionValues[0].value === 'unknown' && !unknown) return accum;
        const index = accum.labels.findIndex((item) => item === curr.dimensionValues[0].value);
        if (index >= 0) {
          accum.datasets[0].data[index] += +curr.metricValues[0].value;
        } else {
          accum.datasets[0].data.push(+curr.metricValues[0].value);
          accum.labels.push(curr.dimensionValues[0].value);
        }
        const colorDec = Math.random() * 16777215;
        const colorNotBlack = colorDec > 3092271 ? colorDec : colorDec + 3092271;
        const colorHex = `#${Math.floor(colorNotBlack).toString(16)}`;

        accum.datasets[0].backgroundColor.push(colorHex);
        return accum;
      }, initialState);
      setChartData(currentData);
    }
  }, [data]);

  const render = () =>
    data.length ? (
      <Bar data={chartData} options={options} />
    ) : (
      <Box component="span" p={10}>
        <Typography variant="h6">No Data</Typography>
      </Box>
    );

  return !loading ? render() : <CircularProgress />;
}
