import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import cx from 'classnames';
import CountUp from 'react-countup';
import impSvg from '../../assets/images/impessions.svg';
import clkSvg from '../../assets/images/clicks.svg';
import dwnSvg from '../../assets/images/downloads.svg';

import { useStyles } from './styles';

const typeSettings = {
  offer_wall: {
    getValue: ({ conversions, unique_clicks }) => +conversions || +unique_clicks,
    showImp: false,
    showClicks: true,
    showConv: true,
    item: 6
  },
  apple_sub: {
    getValue: ({ unique_clicks }) => +unique_clicks,
    showImp: false,
    showClicks: true,
    showConv: false,
    item: 12
  },
  programmatic: {
    getValue: ({ conversions, unique_clicks }) => +conversions || +unique_clicks,
    showImp: true,
    showClicks: true,
    showConv: true,
    item: 4
  },
};

export default function Chart({
  totalStats: { impressions = 0, clicks = 0, conversions = 0, unique_clicks = 0 } = {},
  type = 'offer_wall',
}) {
  const classes = useStyles({
    impSvg,
    clkSvg,
    dwnSvg,
  });
  const settings = typeSettings[type];
  const value = settings.getValue({ conversions, unique_clicks });
  return (
    <Grid
      container
      direction="row"
      className={classes.chartXS}
      item
      md={12}
      justifyContent="space-around"
      alignItems="flex-end"
    >
      {settings.showImp && (
        <Grid
          className={cx(classes.impChartSection, classes.chartSection)}
          container
          direction="column"
          item
          xs={settings.item}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography className={cx(classes.chartData, classes.chartDataImp)}>
            <CountUp end={impressions} duration={2} separator="," />
          </Typography>
          <Typography className={classes.chartData} paragraph>
            Impressions
          </Typography>
        </Grid>
      )}
      {settings.showClicks && (
        <Grid
          className={cx(classes.clkChartSection, classes.chartSection)}
          container
          direction="column"
          item
          xs={settings.item}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography className={cx(classes.chartData, classes.chartDataClk)}>
            <CountUp end={clicks} duration={2} separator="," />
          </Typography>
          <Typography className={classes.chartData} paragraph>
            Clicks
          </Typography>
        </Grid>
      )}
      {settings.showConv && (
        <Grid
          className={cx(classes.dwnldChartSection, classes.chartSection)}
          container
          direction="column"
          item
          xs={settings.item}
          justifyContent="flex-end"
          alignItems="center"
        >
          <Typography className={cx(classes.chartData, classes.chartDataDwn)}>
            <CountUp end={value} duration={2} separator="," />
          </Typography>
          <Typography className={classes.chartData}>Total</Typography>
          <Typography className={classes.chartData}>IAB 2.1</Typography>
          <Typography className={classes.chartData} paragraph>
            Downloads
          </Typography>
        </Grid>
      )}
    </Grid>
  );
}
