import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import cx from 'classnames';

import { getCampaigns } from '../api/campaigns';

import airpodsRight from '../assets/images/gifs/airpod-right.gif';
import airpodsLeft from '../assets/images/gifs/airpod-left.gif';
import Rellax from 'rellax';
import Header from '../components/Header/Header';
import { getRssFeed } from '../api/rss';
import { LoadingImage } from '../components/LoadingImage'
import { numberWithCommas } from '../utils/utils';
import { Grid } from '@material-ui/core';

export default function CampaignList() {
  const history = useHistory();
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const rellax = new Rellax(".rellax", {
      center: true,
    });
    const sortString = (a, b) => (a.podcast > b.podcast) ? 1 : ((b.podcast > a.podcast) ? -1 : 0)
    getCampaigns()
      .then((result) => {
        const campArr = [
          ...result.filter(item => item.status === 'LIVE').sort(sortString),
          ...result.filter(item => item.status === 'IO Sent').sort(sortString),
          ...result.filter(item => item.status === 'COMPLETE').sort(sortString),
          ...result.filter(item => item.status === 'In Setup').sort(sortString),
        ]
        setCampaigns(campArr);
      })
      .catch((e) => {
        console.error('e :>> ', e);
      });
    return () => {
      rellax.destroy();
    }
  }, []);

  const getImgFromFeed = async (RSSLink) => getRssFeed(RSSLink).then(result => result.image.url);

  return (
    <>
      <div className="backgrounds backgrounds--filter">
        <div className="background__rainbow">
          <div className="background__rainbow--image rainbow-list rainbow--first rellax" />
          <div className="background__rainbow--image rainbow-list rainbow--second rellax" />
          <div className="background__rainbow--image rainbow-list rainbow--third rellax" />
        </div>
        <div className="background-sun" />
        <Header />
        <Grid className={'list-page title'} container item md={12} justifyContent="flex-start" alignItems="flex-start">
          <button className="submit-boost--button submit-button" onClick={() => history.push('/addcampaign')}>+ Add new campaign</button>
          <button className="submit-boost--button submit-button" onClick={() => window.open("https://docs.google.com/spreadsheets/d/1BOOXuA3l3q5G9WoWMn2_mdrUG880kPvVkpFW8E6UfmM", "_blank")}>Google Sheet</button>
        </Grid>
        <main className="main-list">
          <img className="airpod-right rellax" data-rellax-speed="2" src={airpodsRight} alt="airpod-right" />
          <img className="airpod-left rellax" data-rellax-speed="2" src={airpodsLeft} alt="airpod-left" />
          <div className="main main-list container" data-aos="fade-up" data-aos-delay="400" data-aos-duration="400">
            <div className="campaigns">
              <span className="campaigns--span orange-text">Your Campaigns:</span>
              <div className="campaigns__list">
                {campaigns.map((item, i) => (
                  <div key={i} className="campaigns__list__podcast">
                    <Link to={`/stats/${item.offerId}`}>
                      <LoadingImage width="100%" getImgUrl={() => getImgFromFeed(item.rss)} alt="podcast preview" />
                    </Link>
                    <div className="campaigns__list__podcast--description">
                      <h5>{item.podcast}</h5>
                      {!!item.offerWallBudget && (<span className='downloads'>${numberWithCommas(item.offerWallBudget)} for {numberWithCommas(item.offerWallDownloads) || 0} downloads</span>)}
                      {!!item.appleSubBudget && (<span className='downloads'>${numberWithCommas(item.appleSubBudget)} for {numberWithCommas(item.appleSubDownloads) || 0} Apple Subscriptions</span>)}
                    </div>
                    <div className={cx("campaigns__list__podcast--prog", { live: item.status !== 'COMPLETE' })}><Link to={`/stats/${item.offerId}`}>{item.status}</Link></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  )
}