import { Field, FormikProvider, useFormik } from 'formik';
import React, { useState } from 'react';
import { NavLink, Redirect } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import cx from 'classnames';

import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { login } from '../../api/auth';
import { initialValues } from './initialValues';
import Logo from '../../assets/images/logo.svg';
import iHeartLogo from '../../assets/images/logo-iheartmedia.png';

import { useStyles } from './styles';

export default function LoginForm() {
  const classes = useStyles();
  const { user, setUser } = useCurrentUser();
  const [error, setError] = useState(null);

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      login(values)
        .then((res) => {
          setUser(res.data.user);
        })
        .catch((e) => {
          console.error(e);
          setError(e.response.data.message);
        });
    },
  });
  if (user) {
    return <Redirect to="/" />;
  }
  return (
    <div className="login-container">
      <div className="login-row">
        <div className="login-block">
          <FormikProvider value={formik}>
            <form onSubmit={formik.handleSubmit} autoComplete="off" className="login-form">
              <div className="login-form-row centered">
                <div className={cx(classes.blockLogo, 'login-form-block')}>
                  <img src={Logo} alt="mowpod-logo" className="login-form-logo" />
                  <img className={classes.hsLogo} src={iHeartLogo} alt="hs-logo" />
                </div>
              </div>
              <div className="login-form-row">
                <div className="login-form-block">
                  <div className="login-form-title">
                    Log In
                  </div>
                </div>
              </div>
              <div className="login-form-row">
                <div className="login-form-block">
                  <label htmlFor="email">E-mail address:</label>
                  <Field type="email" placeholder="email@example.com" name="email" className="login-form-input" required />
                </div>
              </div>
              <div className="login-form-row">
                <div className="login-form-block">
                  <label htmlFor="password">Password:</label>
                  <NavLink to="/forgot-password" className="login-form-link forgot-password">Forgot password?</NavLink>
                  <Field type="password" placeholder="•••••••••••••••" name="password" className="login-form-input" required />
                </div>
              </div>
              <div className="login-form-row">
                {error && (<Typography className={classes.error}>{error}</Typography>)}
              </div>
              <div className="login-form-row centered">
                <div className="login-form-block">
                  <button className="button" type="submit">
                    Log In
                  </button>
                </div>
              </div>
            </form>
          </FormikProvider>
        </div>
      </div>
    </div>
  );
}
