import React, { useEffect } from 'react';
import JotformEmbed from 'react-jotform-embed';
import { Grid, makeStyles } from '@material-ui/core';
import Rellax from 'rellax';
import Header from '../components/Header/Header';

export const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: 1340,
  },
}));

export const AddCampaign = () => {
  const classes = useStyles();
  useEffect(() => {
    new Rellax(".rellax", {
      center: true,
    });
    window.scrollTo(0, 0);
  });
  return (
    <>
      <div className="backgrounds">
        <div className="background__rainbow">
          <div className="background__rainbow--image rainbow--first rellax" />
          <div className="background__rainbow--image rainbow--second rellax" />
          <div className="background__rainbow--image rainbow--third rellax" />
        </div>
        <div className="background-sun" />
      </div>
      <Header />
      <div className={`main container ${classes.container}`}>
        <Grid item md={12}>
          <JotformEmbed src="https://form.jotform.com/230582021790046" />
        </Grid>
      </div>
    </>
  )
}
