/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */

export const getHeaders = () => {
  const authToken = `${localStorage.getItem('auth_token')}`;
  return {
    JWTAuthorization: authToken || '',
  };
};

export const compareObjects = (elemA, elemB) => JSON.stringify(elemA) === JSON.stringify(elemB);

export const cloneObject = (obj) => JSON.parse(JSON.stringify(obj));

export const getHost = () => `${window.location.protocol}//${window.location.hostname}`;

export const isHS = () => window.location.hostname === 'hubspot.mowpod.com';

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const validateEmail = (email) => {
  const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regexp.test(email);
};

export const findUrlInString = (str) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return str.match(urlRegex) ? str.match(urlRegex)[0] : str;
};

export const serialize = (obj, prefix) => {
  const str = [];
  let p;
  for (p in obj) {
    if (obj.hasOwnProperty(p)) {
      let k = prefix ? `${prefix}[${Array.isArray(obj) ? '' : p}]` : p;
      let v = obj[p];
      str.push((v !== null && typeof v === 'object')
        ? serialize(v, k)
        : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
    }
  }
  return str.join('&');
};

export const prepareStatsFilters = (filters) => {
  const preparedFilters = {};
  Object.keys(filters).forEach((key) => {
    if (filters[key].values.length) {
      preparedFilters[key] = filters[key];
    }
  });
  return preparedFilters;
};

export const isInt = (n) => Number(n) === n && n % 1 === 0;

export const resize = (el) => {
  el.style.height = 'inherit';
  el.style.height = `${el.scrollHeight}px`;
};

export const getLength = (str) => str.replace(/ /g, '').length;

export const addSpacesCard = (value) => {
  const cleanNum = value.replace(/[^0-9]/g, "").replace(/\W/gi, '');
  if (cleanNum.length === 15) {
    return cleanNum.replace(/(.{4})(.{6})(.{5})/g, '$1 $2 $3').trim();
  }
  return cleanNum.replace(/(.{4})/g, '$1 ').trim();
};
export const addSlashCardDate = (value) => {
  value = value.replace(/[^0-9]/g, "").replace(/(.{2})/g, '$1/').replace(/^\/|\/$/g, '');
  for (let i = 0; i < value.length; i++) {
    switch (i) {
      case 0:
        if (value[i] > 1) {
          value = `0${value[i]}`;
          i++;
        }
        break;
      case 1:
        value = +value[i - 1] === 1 && value[i] > 2 ? '12' : value;
        break;
      default:
        break;
    }
  }
  return value;
}

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}