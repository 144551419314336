import axios from 'axios';
import { getHeaders } from '../utils/utils';

export const createCampaign = async (params) => {
  const headers = getHeaders();
  const res = await axios.post('/api/campaigns', params, { headers });
  return res.data;
};

export const getCampaign = async (campaingId) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/campaigns/${campaingId}`, { headers });
  return res.data;
};

export const getCampaigns = async () => {
  const headers = getHeaders();
  const res = await axios.get(`/api/campaigns`, { headers });
  return res.data;
}

export const getAllCampaigns = async (params) => {
  const headers = getHeaders();
  const res = await axios.get(`/api/campaigns/all`, { headers, params });
  return res.data;
}