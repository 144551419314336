import {
  Grid,
  makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, withStyles, Typography, InputBase,
} from '@material-ui/core';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { adminLogin } from '../../api/auth';
import { useCurrentUser } from '../../contexts/CurrentUserContext';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { getUsers } from '../../api/users';

const useStyles = makeStyles((theme) => ({
  link: {
    color: '#fff',
  },
  tHead: {
    fontWeight: 'bold',
    backgroundColor: theme,
  },
  tTableContainer: {
    background: 'transparent',
    boxShadow: 'none',
  },
  tHeader: {
    color: 'grey',
    border: 'none',
  },
  tTable: {
    borderCollapse: 'separate',
    borderSpacing: '0px 10px',
  },
  tTablePagination: {
    color: '#fff',
    border: 'none',
  },
  tFirstItem: {
    border: 'none',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  tBodyItem: {
    border: 'none',
  },
  wrapper: {
    paddingRight: 30,
  },
  title: {
    marginTop: 30,
  },
  iconButton: {
    color: '#fff',
  },
  nowrap: {
    whiteSpace: 'nowrap',
  },
  input: {
    border: '1px solid #fff',
    padding: '5px 15px',
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    color: '#fff',
  },
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#1C1B1B',
      color: '#fff',
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#262424',
      color: '#fff',
    },
  },
}))(TableRow);

function Row({ row }) {
  const { setUser } = useCurrentUser();
  const classes = useStyles();

  const loginUser = ({ _id: userId, source }) => {
    adminLogin(userId)
      .then(response => {
        setUser(response.data.user)
        window.open(`/`, '_blank').focus();
      })
      .catch(e => {
        toast.error(e.mesage);
      })
  };

  return (
    <>
      <StyledTableRow>
        <StyledTableCell align="left" className={classes.tBodyItem}>
          {row.email}
        </StyledTableCell>
        <StyledTableCell align="right" className={cx(classes.tBodyItem, classes.nowrap)}>
          <a style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => loginUser(row)}>login</a>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};

export default function Users() {
  const classes = useStyles();
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  useEffect(() => {
    setLoading(true);
    getUsers()
      .then((result) => {
        setCampaigns(result);
        setLoading(false);
      });
  }, []);

  const keyPress = (e) => {
    const { value } = e.target;
    setSearch(value);
    setLoading(true);
    if (e.keyCode === 13) {
      getUsers({ search })
        .then((result) => {
          setCampaigns(result);
          setLoading(false);
        })
        .catch(err => {
          toast.error(err.message)
        });
    }
  };

  return (
    <>
      <Grid className={classes.wrapper} container direction="row" item md={12} justifyContent="center" alignItems="flex-start">
        <Grid className={classes.title} container direction="row" item md={12} justifyContent="flex-start" alignItems="flex-start">
          <Grid className={classes.title} container direction="row" item md={6}>
            <Typography variant="h4">Users</Typography>
          </Grid>
          <Grid className={classes.title} container direction="row" item md={6} justifyContent="flex-end">
            <InputBase
              className={classes.input}
              placeholder="Search"
              value={search}
              onKeyDown={keyPress}
              onChange={(e) => setSearch(e.target.value)}
              inputProps={{ 'aria-label': 'search users' }}
            />
          </Grid>
        </Grid>
        {!loading ? (
          <TableContainer className={classes.tTableContainer} component={Paper}>
            <Table aria-label="simple table" className={classes.tTable}>
              <TableHead className={classes.tHead}>
                <TableRow style={{ border: 'none' }}>
                  <TableCell className={classes.tHeader} align="left">Email</TableCell>
                  <TableCell className={classes.tHeader} align="right">Login Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {campaigns.map((campaign) => (
                  <Row key={campaign._id} row={campaign} />
                ))}
                {!campaigns.length && (
                  <TableRow>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Grid container direction="row" item md={12} justifyContent="center">
            <FontAwesomeIcon icon={faSpinner} size="lg" spin />
          </Grid>
        )}
      </Grid>
    </>
  );
}
