import { Button, FormControl, Grid, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { useParams } from 'react-router-dom';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { useStyles } from './styles';
import { getGAStats, getOfferUrls, getStats } from '../../api/stats';
import TopDevices from './charts/TopDevices';
import TopCities from './TopCities';

import bgBtn from '../../assets/images/button.png';
import bgBtnGray from '../../assets/images/button-gray.png';
import Chart from './chart';
import Header from '../../components/Header/Header';
import Rellax from 'rellax';
import { getRssFeed } from '../../api/rss';
import { numberWithCommas, prepareStatsFilters, serialize } from '../../utils/utils';
import TopOs from './TopOs';
import { getCampaign } from '../../api/campaigns';
import TopInterests from './TopInterests';
import TopAgeAndGender from './charts/TopAgeAndGender';
import {
  getCampaignGroups,
  getStackDevices,
  getStackGeos,
  getStackStats,
} from '../../api/stackadapt';

const periods = [
  {
    label: 'Today',
    value: 'today',
    getTimeInterval: () => ({
      start: moment().startOf('day').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Yesterday',
    value: 'yesterday',
    getTimeInterval: () => ({
      start: moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last 7 days',
    value: 'week',
    getTimeInterval: () => ({
      start: moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Month to date',
    value: 'currentMonth',
    getTimeInterval: () => ({
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last month',
    value: 'lastMonth',
    getTimeInterval: () => ({
      start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
    }),
  },
  {
    label: 'Last 6 months',
    value: 'lastSixMonth',
    getTimeInterval: () => ({
      start: moment().subtract(6, 'month').startOf('day').format('YYYY-MM-DD'),
      end: moment().endOf('day').format('YYYY-MM-DD'),
    }),
  },
];

export default function PodcastStats() {
  const classes = useStyles({ bgBtn, bgBtnGray });

  const [campaignGroups, setCampaignGroups] = useState({});
  const [startDate, setStartDate] = useState(moment().subtract(6, 'months').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));
  const [stats, setStats] = useState({});
  const [devicesLoading, setDevicesLoading] = useState(false);
  const [statesLoading, setStatesLoading] = useState(false);
  const [osLoading, setOsLoading] = useState(false);
  const [ageLoading, setAgeLoading] = useState(false);
  const [interestsLoading, setInterestsLoading] = useState(false);
  const [genderStatsLoading, setGenderStatsLoading] = useState(false);
  const [img, setImg] = useState();
  const [openStartDate, setOpenStartDate] = useState(false);
  const [openEndDate, setOpenEndDate] = useState(false);
  const [topDevices, setTopDevices] = useState();
  const [topStates, setTopStates] = useState();
  const [topOs, setTopOs] = useState();
  const [interests, setInterests] = useState([]);
  const [genderStats, setGenderStats] = useState([]);
  const [ageStats, setAgeStats] = useState([]);
  const [offerUrls, setOfferUrls] = useState([]);
  const [episodes, setEpisodes] = useState([]);
  const [selected, setSelected] = useState(0);
  const [hasIos, setHasIos] = useState(false);
  const [filters, setFilters] = useState({
    'Stat.date': {
      conditional: 'BETWEEN',
      values: [moment().subtract(6, 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    },
  });

  const { id } = useParams();

  const getStatistics = (offerId) => {
    if (!offerId) return;
    setStatesLoading(true);
    setDevicesLoading(true);
    setOsLoading(true);
    const preparedFilters = prepareStatsFilters(filters);
    const strFilters = serialize({ filters: preparedFilters });
    getStats(offerId, strFilters, 'totals')
      .then((stats) => setStats(stats))
      .catch((err) => console.error(err));
    getStats(offerId, strFilters, 'states', 10)
      .then((result) => {
        setTopStates(result);
        setStatesLoading(false);
      })
      .catch(() => setStatesLoading(false));
    getStats(offerId, strFilters, 'devices', 10)
      .then((result) => {
        setTopDevices(result);
        setDevicesLoading(false);
      })
      .catch(() => setDevicesLoading(false));
    getStats(offerId, strFilters, 'os', 10)
      .then((result) => {
        setTopOs(result);
        setOsLoading(false);
      })
      .catch(() => setOsLoading(false));
  };

  const getStackStatistics = async (id) => {
    setStatesLoading(true);
    setDevicesLoading(true);
    setOsLoading(true);
    let ids = id;
    let paths = [];
    if (id === 0) {
      ids = episodes.map((item) => item.id);
      paths = episodes.reduce((accum, item) => {
        const url = new URL(item.campaign.all_native_ads[0].click_url);
        return [...accum, `${url.pathname}${url.search}`];
      }, []);
    } else {
      const campaignGroup = episodes.find((item) => +item.id === +id);
      const url = new URL(campaignGroup.campaign.all_native_ads[0].click_url);
      paths.push(`${url.pathname}${url.search}`);
    }

    getStackStats({
      resource: 'line_item',
      type: 'total',
      ids,
      startDate,
      endDate,
    }).then((stats) => {
      setStats(stats);
    });

    getStackGeos({
      resource: 'line_item',
      ids,
      startDate,
      endDate,
    }).then((states) => {
      setTopStates(states);
      setStatesLoading(false);
    });

    getStackDevices({
      resource: 'line_item',
      ids,
      startDate,
      endDate,
    }).then((states) => {
      setTopDevices(states);
      setDevicesLoading(false);
    });

    getGaStats(paths);
    setOsLoading(false);
  };

  const getGaStats = (paths) => {
    setInterestsLoading(true);
    setAgeLoading(true);
    setGenderStatsLoading(true);
    if (!paths.length) {
      setAgeLoading(false);
      setInterestsLoading(false);
      setGenderStatsLoading(false);
      return paths;
    }
    getGAStats({
      type: 'brandingInterest',
      startDate,
      endDate,
      paths,
    }).then((response) => {
      setInterests(response);
      setInterestsLoading(false);
    });
    getGAStats({
      type: 'userGender',
      startDate,
      endDate,
      paths,
    }).then((response) => {
      setGenderStats(response);
      setGenderStatsLoading(false);
    });
    getGAStats({
      type: 'userAgeBracket',
      startDate,
      endDate,
      paths,
    }).then((response) => {
      setAgeStats(response);
      setAgeLoading(false);
    });
  };

  const getTuneData = (campaignResult) => {
    return getOfferUrls(campaignResult.offerId).then((OfferUrlsResult) => {
      const offerUrls = Object.values(OfferUrlsResult);
      setOfferUrls(offerUrls);
      const paths = offerUrls.map((item) => {
        const url = new URL(item.OfferUrl.offer_url);
        return `${url.pathname}${url.search}`;
      });
      getGaStats(paths);
      getRssFeed(campaignResult.rss).then((rss) => {
        setImg(rss.image.url);
      });
      setFilters((prevState) => ({
        ...prevState,
        'Stat.offer_url_id': {
          conditional: 'EQUAL_TO',
          values: offerUrls.map((item) => item.OfferUrl.id),
        },
      }));
    });
  };

  const getStackData = async (campaignResult) => {
    const { keyphrase, advertiserId } = campaignResult;
    const [rss, campaignGroups] = await Promise.all([
      getRssFeed(campaignResult.rss),
      getCampaignGroups({ keyphrase, advertiserId }),
    ]);
    setImg(rss.image.url);
    setEpisodes(() => campaignGroups);
  };

  useEffect(() => {
    if (episodes.length) getStackStatistics(0);
  }, [episodes]);

  useEffect(() => {
    new Rellax('.rellax', {
      center: true,
    });
    window.scrollTo(0, 0);
    if (id) {
      getCampaign(id).then((campaignResult) => {
        setHasIos(campaignResult.productType === 'apple_sub');
        setCampaignGroups(campaignResult);
        if (campaignResult.productType === 'programmatic' && campaignResult.keyphrase) {
          return getStackData(campaignResult);
        }
        return getTuneData(campaignResult);
      });
    }
  }, []);

  useEffect(() => {
    if (!campaignGroups.keyphrase) {
      return getStatistics(campaignGroups.offerId);
    }
  }, [filters]);

  const handleStartDateChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    setStartDate(date);
    setFilters((prevState) => ({
      ...prevState,
      'Stat.date': {
        conditional: 'BETWEEN',
        values: [date, endDate],
      },
    }));
    setOpenStartDate(false);
    setOpenEndDate(true);
  };

  const handleEndDateChange = (value) => {
    const date = moment(value).format('YYYY-MM-DD');
    setEndDate(date);
    setFilters((prevState) => ({
      ...prevState,
      'Stat.date': {
        conditional: 'BETWEEN',
        values: [startDate, date],
      },
    }));
    setOpenEndDate(false);
  };

  const setDateInterval = ({ start, end }) => {
    setStartDate(() => start);
    setEndDate(() => end);
    setFilters((prevState) => ({
      ...prevState,
      'Stat.date': {
        conditional: 'BETWEEN',
        values: [start, end],
      },
    }));
  };

  const handleEpisode = (e) => {
    const { value } = e.target;
    setSelected(value);
    if (value === '0') {
      const paths = offerUrls.map((item) => {
        const url = new URL(item.OfferUrl.offer_url);
        return `${url.pathname}${url.search}`;
      });
      if (paths.length) {
        getGaStats(paths);
      }
      return setFilters((prevState) => {
        const { 'Stat.offer_url_id': OfferUrlId, ...rest } = prevState;
        return rest;
      });
    }
    const offerUrl = offerUrls.find((item) => item.OfferUrl.id === value);
    const url = new URL(offerUrl.OfferUrl.offer_url);
    getGaStats([`${url.pathname}${url.search}`]);
    return setFilters((prevState) => ({
      ...prevState,
      'Stat.offer_url_id': {
        conditional: 'EQUAL_TO',
        values: [value],
      },
    }));
  };

  const handleStackEpisode = (e) => {
    const { value } = e.target;
    setSelected(value);
    getStackStatistics(+value);
  };

  const getSummary = () => {
    const budget = numberWithCommas(campaignGroups.budget);
    const {
      rowsCount,
      offerWallDownloads,
      appleSubDownloads,
      deliveredOmny,
      deliveredApple,
      live,
      sent,
      setup,
      noAccess,
    } = campaignGroups;
    const offerWallDownloadsText = offerWallDownloads
      ? `${numberWithCommas(String(offerWallDownloads))} Downloads in mowPod boost`
      : '';
    const appleSubDownloadsText = appleSubDownloads
      ? `${numberWithCommas(String(appleSubDownloads))} followers in Apple Sub`
      : '';
    let downloadsText = '';
    if (offerWallDownloadsText && appleSubDownloadsText) {
      downloadsText = `${offerWallDownloadsText} and ${appleSubDownloadsText}`;
    } else {
      downloadsText = `${offerWallDownloadsText}${appleSubDownloadsText}`;
    }
    const deliveredOmnyText =
      !!deliveredOmny &&
      `mowPod delivered ${numberWithCommas(deliveredOmny)} total downloads according to Omny. `;
    const deliveredAppleText =
      !!deliveredApple &&
      `mowPod delivered ${numberWithCommas(deliveredApple)} total Apple Subscribers. `;

    const statusLiveText =
      !!live.count &&
      `There ${live.count > 1 ? 'are' : 'is'} currently ${live.count} live campaign${
        live.count > 1 ? 's' : ''
      } for ${numberWithCommas(live.downloads)} downloads. `;
    const statusSentText =
      !!sent.count &&
      `There ${sent.count > 1 ? 'are' : 'is'} currently ${sent.count} campaign${
        sent.count > 1 ? 's' : ''
      } in Setup. `;
    const statusSetupText =
      !!setup.count &&
      `There ${setup.count > 1 ? 'are' : 'is'} currently ${setup.count} campaign${
        setup.count > 1 ? 's' : ''
      } with IOs sent for signature. `;
    const noAccessText = noAccess && 'We currently have No Access to Omny for this Podcast.';
    const sentClicks =
      hasIos &&
      `mowPod sent ${numberWithCommas(
        stats?.totals?.Stat?.unique_clicks || 0
      )} clicks to get those subscriptions`;
    return (
      <Typography variant="h6">
        This podcast has a total budget of ${budget}, submitted over {rowsCount} IO
        {rowsCount > 1 ? 's' : ''} for {downloadsText}. {deliveredOmnyText}
        {deliveredAppleText}
        {statusLiveText}
        {statusSentText}
        {statusSetupText}
        {noAccessText}
        {sentClicks}
      </Typography>
    );
  };

  const renderOptions = () => {
    if (campaignGroups.productType === 'programmatic' && campaignGroups.keyphrase) {
      return (
        <Select value={selected} onChange={handleStackEpisode}>
          <MenuItem value="0">All Campaigns</MenuItem>
          {episodes.map((item, i) => (
            <MenuItem key={item.id} value={item.id}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      );
    }
    return (
      <Select value={selected} onChange={handleEpisode}>
        <MenuItem value="0">All Campaigns</MenuItem>
        {offerUrls.map((item, i) => (
          <MenuItem key={item.OfferUrl.id} value={item.OfferUrl.id}>
            {item.OfferUrl.name}
          </MenuItem>
        ))}
      </Select>
    );
  };

  return (
    <>
      <div className="backgrounds">
        <div className="background__rainbow">
          <div className="background__rainbow--image rainbow--first rellax" />
          <div className="background__rainbow--image rainbow--second rellax" />
          <div className="background__rainbow--image rainbow--third rellax" />
        </div>
        <div className="background-sun" />
      </div>
      <Header />
      <div className={`main container ${classes.container}`}>
        <Grid
          container
          direction="row"
          item
          md={12}
          className={classes.wrapper}
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          {/* <Grid className={classes.wrapperTitle} container direction="row" item md={6} justifyContent="flex-end" alignItems="center">
            <Button variant="contained" type="submit" disabled={loading} className={cx(classes.button, classes.gray)}>PRINT REPORT</Button>
          </Grid> */}
          <Grid
            style={{ paddingRight: 15 }}
            container
            direction="row"
            item
            md={7}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {/* Podcast Img, Periods */}
            <Grid
              className={classes.wrapperStats}
              container
              direction="row"
              item
              md={12}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                container
                direction="row"
                className={classes.imgSection}
                item
                md={3}
                justifyContent="flex-start"
                alignItems="center"
              >
                {img && (
                  <img src={img} style={{ width: '100%' }} alt={campaignGroups.podcastName} />
                )}
              </Grid>
              <Grid
                container
                direction="row"
                item
                md={9}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid
                  container
                  direction="row"
                  item
                  md={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid
                    container
                    className={classes.datePicker}
                    direction="row"
                    item
                    md={5}
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid container direction="row" item xs={3} alignItems="center">
                      <Typography style={{ color: '#000', fontSize: 10, fontWeight: 'bold' }}>
                        From
                      </Typography>
                    </Grid>
                    <Grid container direction="row" item xs={9} alignItems="center">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          variant="inline"
                          format="MM-dd-yyyy"
                          open={openStartDate}
                          maxDate={endDate}
                          onClose={() => setOpenStartDate(false)}
                          value={startDate}
                          onChange={handleStartDateChange}
                          InputProps={{
                            disableUnderline: true,
                            onClick: () => setOpenStartDate(true),
                            classes: { input: classes.inputDatePicker },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    className={classes.datePicker}
                    direction="row"
                    item
                    md={5}
                    xs={12}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Grid container direction="row" item xs={3} alignItems="center">
                      <Typography style={{ color: '#000', fontSize: 10, fontWeight: 'bold' }}>
                        To
                      </Typography>
                    </Grid>
                    <Grid container direction="row" item xs={9} alignItems="center">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          variant="inline"
                          format="MM-dd-yyyy"
                          open={openEndDate}
                          minDate={startDate}
                          onClose={() => setOpenEndDate(false)}
                          value={endDate}
                          onChange={handleEndDateChange}
                          InputProps={{
                            disableUnderline: true,
                            onClick: () => setOpenStartDate(true),
                            classes: { input: classes.inputDatePicker },
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  style={{ marginTop: 15 }}
                  item
                  md={12}
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {periods.map((item, i) => {
                    const { start, end } = item.getTimeInterval();
                    const active = endDate === end && startDate === start;
                    return (
                      <Grid
                        key={i}
                        container
                        direction="row"
                        item
                        md={4}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          key={i}
                          variant="contained"
                          className={cx(classes.periodBtn, { active })}
                          type="button"
                          onClick={() => setDateInterval(item.getTimeInterval())}
                        >
                          {item.label}
                        </Button>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
            {campaignGroups?.offerId && (
              <Grid
                container
                className={classes.wrapperStats}
                direction="row"
                item
                md={12}
                justifyContent="flex-start"
                alignItems="center"
              >
                {getSummary()}
              </Grid>
            )}
            {/* Select Campaign */}
            <Grid
              container
              direction="row"
              item
              md={12}
              justifyContent="flex-start"
              alignItems="center"
            >
              <FormControl variant="outlined" className={classes.formControl}>
                {renderOptions()}
              </FormControl>
            </Grid>
            {/* Chart */}
            <Grid
              className={classes.wrapperStats}
              container
              direction="row"
              item
              md={12}
              justifyContent="space-around"
              alignItems="flex-end"
            >
              <Chart totalStats={stats.totals?.Stat || {}} type={campaignGroups.productType} />
            </Grid>
            {/* Top Device  */}
            {!hasIos && (
              <Grid
                container
                className={classes.wrapperStats}
                direction="row"
                item
                md={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography
                  style={{ color: '#4faa30', fontSize: 26, fontWeight: 'bold', marginBottom: 15 }}
                >
                  TOP Devices
                </Typography>
                <Grid
                  container
                  direction="row"
                  item
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <TopDevices data={topDevices?.data} loading={devicesLoading} />
                </Grid>
              </Grid>
            )}
            {/* Age */}
            {!hasIos && (
              <Grid
                container
                className={classes.wrapperStats}
                direction="row"
                item
                md={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography
                  style={{ color: '#4faa30', fontSize: 26, fontWeight: 'bold', marginBottom: 15 }}
                >
                  Age
                </Typography>
                <Grid
                  container
                  direction="row"
                  item
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <TopAgeAndGender data={ageStats} loading={ageLoading} />
                </Grid>
              </Grid>
            )}
            {/* Gender */}
            {!hasIos && (
              <Grid
                container
                className={classes.wrapperStats}
                direction="row"
                item
                md={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography
                  style={{ color: '#4faa30', fontSize: 26, fontWeight: 'bold', marginBottom: 15 }}
                >
                  Gender
                </Typography>
                <Grid
                  container
                  direction="row"
                  item
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <TopAgeAndGender
                    data={genderStats}
                    loading={genderStatsLoading}
                    barPercentage={0.4}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid
            container
            direction="row"
            item
            md={5}
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            {/* Top cities */}
            <Grid
              container
              className={classes.wrapperStats}
              direction="row"
              item
              md={12}
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid
                style={{ marginBottom: 20 }}
                container
                direction="row"
                item
                md={12}
                justifyContent="flex-start"
                alignItems="center"
              >
                <Typography style={{ fontSize: 26, color: '#f9a119', fontWeight: 'bold' }}>
                  Top States
                </Typography>
              </Grid>
              <Grid
                container
                direction="row"
                item
                md={12}
                justifyContent="center"
                alignItems="center"
              >
                <TopCities data={topStates?.data} loading={statesLoading} hasIos={hasIos} />
              </Grid>
            </Grid>
            {/* Top OS */}
            {!hasIos && (
              <Grid
                container
                className={classes.wrapperStats}
                direction="row"
                item
                md={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography
                  style={{ color: '#4faa30', fontSize: 26, fontWeight: 'bold', marginBottom: 15 }}
                >
                  TOP Operating Systems
                </Typography>
                <Grid
                  container
                  direction="row"
                  item
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <TopOs data={topOs?.data} loading={osLoading} />
                </Grid>
              </Grid>
            )}
            {/* Top interests */}
            {!hasIos && (
              <Grid
                container
                className={classes.wrapperStats}
                direction="row"
                item
                md={12}
                justifyContent="flex-start"
                alignItems="flex-start"
              >
                <Typography
                  style={{ color: '#4faa30', fontSize: 26, fontWeight: 'bold', marginBottom: 15 }}
                >
                  TOP Interests
                </Typography>
                <Grid
                  container
                  direction="row"
                  item
                  md={12}
                  justifyContent="center"
                  alignItems="center"
                >
                  <TopInterests data={interests} loading={interestsLoading} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
