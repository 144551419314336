import React from 'react';
import ReactDOM from 'react-dom/client';
import preloader from './assets/images/mowpod-boost-logo.png';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import iHeartLogo from './assets/images/logo-iheartmedia.png';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <>
    <App />
    <div id="preloader" className="preloader show-preloader">
      <img src={preloader} alt="logotype" />
     <img src={iHeartLogo} style={{width: "210px"}} alt="logotype" />
    </div>
  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
