import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const LoadingImage = ({
 alt, width, heigth, getImgUrl
}) => {
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState()
  const imageLoaded = () => {
    setLoading(false);
  };
  useEffect(() => {
    setLoading(true);
    getImgUrl()
      .then((url) => setUrl(url) );
  }, []);

  return (
    <>
      <img
        style={{ display: loading ? 'none' : 'block' }}
        alt={alt}
        src={url}
        width={width}
        heigth={heigth}
        onLoad={imageLoaded}
      />
      {loading && (<FontAwesomeIcon icon={faSpinner} size="lg" spin />)}
    </>
  );
};
